import {useState, useEffect} from 'react'
import Header from '../compunet/Header'
import Footer from '../compunet/Footer'
import BaseUrl from "../baseurl/BaseUrl";
// import { PostApiWithOutToken } from "../Helper/helper";
import { getApiWithToken } from "../Helper/helper";
import { useParams } from 'react-router-dom';

const SurrogateDetail = (params) => {
    const param=useParams()
    console.log(param.id);
  useEffect(() => {
    
    window.scrollTo(0, 0);
  
    getData(); // Assuming getData is a function defined outside useEffect
    // getDatas();
    // statusll("pending")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let parentData=""
        const parentDataString = localStorage.getItem("parentdata");
if (parentDataString) {
   parentData = JSON.parse(parentDataString);
  console.log(parentData);
} else {
  console.log("No data found in localStorage.");
}
console.log(parentData);
  const [userData,] = useState(localStorage.getItem("tokenparent"));
//   console.log(userData);
//   const [datas, setdatas] = useState()
  const [dataall, setdataall] = useState()

  const getData = () => {
    // setLoader(true)
    getApiWithToken(`${BaseUrl.baseUrl}parent/surrogate/${param.id}`, "", userData)
      .then(({ data }) => {
        console.log("atttaaaa", data.surrogate[0]);
        console.log("minhaj", data);

        // setdatas(data)
        setdataall(data.surrogate[0])
        // console.log(datas);

        console.log(dataall);

        // setfirst_detail(data.home?.first_detail)
        // setsecond_detail(data.home?.second_detail)
        // setthird_detail(data.home?.third_detail)
        // setfour_detail(data.home?.four_detail)
        // setfifth_detail(data.home?.fifth_detail)
         
        
        // setLoader(false)
      })
      .catch((err) => {
        console.log("err---------", err);
      });


};
  return (
    <>
      <body className="moto-background moto-website_live">
        <div className="page">
          <Header />
          <section
          style={{marginTop:"100px"}}
            id="section-content"
            className="content page-1 moto-section"
            data-widget="section"
            data-container="section"
          >
            <div
              id="wid_1595841006_s9ff8o2hk"
              className="moto-widget moto-widget-content_slider moto-widget_interactive moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto   moto-widget-content_slider-controls-visible-on_tablet_hidden moto-widget-content_slider-controls-visible-on_mobile-h_hidden moto-widget-content_slider-controls-visible-on_mobile-v_hidden moto-widget__state_loading"
              data-widget="content_slider"
              data-preset="default"
              data-moto-content-slider='{"preferences":{"startAnimation":"onArriving"},"options":{"auto":true,"pause":3000,"mode":"fade","controls":true,"pager":false,"autoControls":false}}'
            >
              <div
                id="wid_1595841006_s9ff8o2hk__loader"
                className=""
              >
                <div className="moto-widget-loader__indicator" />
              </div>
             
            </div>

        
            <div
              className="moto-widget moto-widget-row row-fixed row-gutter-0 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
              data-grid-type="sm"
              data-widget="row"
              data-visible-on="-"
              data-spacing="aaaa"
              style={{}}
              data-bg-position="left top"
            >
                <div className="">
                                <div
                                    id="wid_1595841006_s9ff8o2hk__content"
                                    className="moto-widget__content"
                                >
                                    <div
                                        id="wid_1595841023_rrwvo0wxl"
                                        data-parent-id="wid_1595841006_s9ff8o2hk"
                                        className="moto-widget moto-widget-content_slider__item moto-widget-row moto-widget_with-content-editor  row-fixed moto-disabling_row-fixed moto-entertainment__playing_disabled moto-before-in-viewport_content-invisible"
                                        style={{
                                            backgroundPosition: "top",
                                            backgroundImage:
                                                "url(mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_home_bg03.jpg)",
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat"
                                        }}
                                        data-widget="content_slider.item"
                                        data-preset=""
                                    >
                                        <div className="moto-widget__content-wrapper container-fluid">
                                            <div className="moto-widget__content row">
                                                <div
                                                    id="wid_1595841023_rrwvo0wxl__content"
                                                    className="moto-cell col-sm-12 moto-entertainment__animation_disabled moto-entertainment__animation_ignore-visibility"
                                                >
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                 </div>

   
<div class="container">
	<div class="row mb-5">
		<div class="col-md-6">
			<div class="row">
				<div class="col-md-12 mb-4 d-flex justify-content-center">
					<img alt="" class="img-thumbnail p-0 border-0 w-75 h-75"  src={dataall?.image ? `https://api.psc.mycompanyportfolio.com/${dataall?.image} ` : "/image/profile.png"} />
				</div>
                {dataall?.images?.map((e)=>{
                    return(

               
				<div class="col-md-3 mb-4">
					<img alt="" class="img-thumbnail p-0 border-0" src={`https://api.psc.mycompanyportfolio.com/${e?.name}`} />
				</div>
                     )
                    })
}
				{/* <div class="col-md-3 mb-4">
					<img alt="" class="img-thumbnail p-0 border-0" src="https://images.unsplash.com/photo-1568702846914-96b305d2aaeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" />
				</div>
				<div class="col-md-3 mb-4">
					<img alt="" class="img-thumbnail p-0 border-0" src="https://images.unsplash.com/photo-1568702846914-96b305d2aaeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" />
				</div>
				<div class="col-md-3 mb-4">
					<img alt="" class="img-thumbnail p-0 border-0" src="https://images.unsplash.com/photo-1568702846914-96b305d2aaeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" />
				</div>
				<div class="col-md-3">
					<img alt="" class="img-thumbnail p-0 border-0" src="https://images.unsplash.com/photo-1568702846914-96b305d2aaeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" />
				</div>
				<div class="col-md-3">
					<img alt="" class="img-thumbnail p-0 border-0" src="https://images.unsplash.com/photo-1568702846914-96b305d2aaeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" />
				</div>
				<div class="col-md-3">
					<img alt="" class="img-thumbnail p-0 border-0" src="https://images.unsplash.com/photo-1568702846914-96b305d2aaeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" />
				</div>
				<div class="col-md-3">
					<img alt="" class="img-thumbnail p-0 border-0" src="https://images.unsplash.com/photo-1568702846914-96b305d2aaeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" />
				</div> */}
			</div>
		</div>
		<div class="col-md-3">
			<div class="card">
				<div class="card-body">
					{/* <h5> <span style={{fontWeight:"700"}}>First Name : </span><span style={{fontWeight:"500"}}>{dataall?.first_name}</span></h5> */}
					
					<ul class="list-group list-group-flush mb-4">
						{/* <li class="list-group-item pl-0 pr-0 pt-2 pb-2">Brand: <Link to="#">Apple</Link></li> */}
						{/* <li class="list-group-item pl-0 pr-0 pt-2 pb-2">Model: 10 Pro Max</li>
                        <li class="list-group-item pl-0 pr-0 pt-2 pb-2">Model: 10 Pro Max</li> */}
                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                           <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700",fontSize:"16px"}}>First Name : </span><span style={{fontWeight:"500"}}>{dataall?.first_name}</span></li>
                        </div>

                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                          <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Last Name : </span><span style={{fontWeight:"500"}}>{dataall?.last_name}</span></li>
                          </div>


                          <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Email : </span><span style={{fontWeight:"500"}}>{dataall?.email}</span></li>
                        </div>

                 
                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Phone Number : </span><span style={{fontWeight:"500"}}>{dataall?.phone_number}</span></li>
                        </div>


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>State : </span><span style={{fontWeight:"500"}}>{dataall?.state}</span></li>
                        </div>

						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Personal History : </span><span style={{fontWeight:"500"}}>{dataall?.personal_history}</span></li>
                        </div>
						
						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Us Citizen : </span><span style={{fontWeight:"500"}}>{dataall?.us_citizen}</span></li>
                        </div>
						

                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <h5 className='pl-0 pr-0 pt-2 pb-2 pl-2'><h4 style={{fontWeight:"800"}}>Personal Information</h4></h5>
                        </div>


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>About Us : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.about_us}</span></li>
                        </div>


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Date of Birth : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.date_of_birth}</span></li>
                        </div>


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Contact Email Address : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.contact_email_address}</span></li>
                        </div>

                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Convicted of a Felony : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.convicted_of_a_felony}</span></li>
                        </div>
                        
                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Age : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.age}</span></li>
                        </div>

                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>City : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.city}</span></li>
                        </div>

                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>weight : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.weight}</span></li>
                        </div>

                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Height Feet : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.height_feet}</span></li>
                        </div>


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Height Inches : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.height_inches}</span></li>
                        </div>


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Surrogate : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.surrogate}</span></li>
                        </div>

						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                           <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700",fontSize:"16px"}}>BMI : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.bmi}</span></li>
                        </div>
                        
                        
						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                          <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Country Born In : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.country_born_in}</span></li>
                          </div>
                        
                        
						
                        
					</ul>
					{/* <fieldset>
						<legend>Available Options</legend>
						<div class="form-group row">
							<label for="" class="col-sm-12 col-form-label">Size</label>
							<div class="col-sm-12">
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize1" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize1">34</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize2" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize2">36</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize3" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize3">38</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize4" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize4">40</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize5" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize5">42</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize6" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize6">44</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize7" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize7">46</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize8" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize8">48</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize9" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize9">50</label>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<label for="" class="col-sm-12 col-form-label">Color</label>
							<div class="col-sm-12">
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize1" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize1">
										<div class="p-2 mt-1" style={{backgroundColor:"#ffcc00"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize2" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize2">
										<div class="p-2 mt-1" style={{backgroundColor:"#66cccc"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize3" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize3">
										<div class="p-2 mt-1" style={{backgroundColor:"#0fabbc"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize4" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize4">
										<div class="p-2 mt-1" style={{backgroundColor:"#004445"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize5" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize5">
										<div class="p-2 mt-1" style={{backgroundColor:"#eb4d55"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize6" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize6">
										<div class="p-2 mt-1" style={{backgroundColor:"#ff9d76"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize7" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize7">
										<div class="p-2 mt-1" style={{backgroundColor:"#90303d"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize8" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize8">
										<div class="p-2 mt-1" style={{backgroundColor:"#015668"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize9" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize9">
										<div class="p-2 mt-1" style={{backgroundColor:"#ffd369"}}></div>
									</label>
								</div>
							</div>
						</div>
					</fieldset> */}
					{/* <div class="input-group input-group-lg mb-4">
						<input type="number" class="form-control text-center" placeholder="Quantity" id="" name="" value="1" min="1" max="5" />
						<div class="input-group-append">
							<span class="input-group-text">Quantity</span>
						</div>
					</div> */}
					{/* <div class="btn-group btn-group-lg" role="group" aria-label="Basic example">
						<button type="button" class="btn btn-secondary">
							<i class="fas fa-cart-plus"></i>
							<span>Add Cart</span>
						</button>
						<button type="button" class="btn btn-secondary"><i class="fas fa-shopping-cart"></i></button>
						<button type="button" class="btn btn-secondary"><i class="fas fa-heart"></i></button>
						<button type="button" class="btn btn-secondary"><i class="fas fa-exchange-alt"></i></button>
					</div> */}
				</div>
			</div>
		</div>
		<div class="col-md-3">
			<div class="card">
				<div class="card-body">
					{/* <h5> <span style={{fontWeight:"700"}}>First Name : </span><span style={{fontWeight:"500"}}>{dataall?.first_name}</span></h5> */}
					
					<ul class="list-group list-group-flush mb-4">
						{/* <li class="list-group-item pl-0 pr-0 pt-2 pb-2">Brand: <Link to="#">Apple</Link></li> */}
						{/* <li class="list-group-item pl-0 pr-0 pt-2 pb-2">Model: 10 Pro Max</li>
                        <li class="list-group-item pl-0 pr-0 pt-2 pb-2">Model: 10 Pro Max</li> */}
                      

                    
						  

                          <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>County : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.county}</span></li>
                        </div>

						
                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Driver license or id card : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.driver_license_or_id_card}</span></li>
                        </div>


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Emergency First Name : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.emergency_first_name}</span></li>
                        </div>
						
		

                      


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Emergency Last Name : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.emergency_last_name}</span></li>
                        </div>


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Emergency Phone Number : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.emergency_phone_number}</span></li>
                        </div>


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Ethnic Background : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.ethnic_background}</span></li>
                        </div>

                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Financially stable : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.financially_stable}</span></li>
                        </div>
                        
                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Last six month traveled : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.last_six_month_traveled}</span></li>
                        </div>

						

                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Legal citizanship status : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.legal_citizanship_status}</span></li>
                        </div>

                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Number of csection : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.number_of_csection}</span></li>
                        </div>

                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Number of deliveries : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.number_of_deliveries}</span></li>
                        </div>


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Over the age of 18 live : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.over_the_age_of_18_live}</span></li>
                        </div>


                        <div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Relationship status : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.relationship_status}</span></li>
                        </div>


						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Reliable transportation : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.reliable_transportation}</span></li>
                        </div>

						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Sexual orientation : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.sexual_orientation}</span></li>
                        </div>

						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Sexually activity : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.sexually_activity}</span></li>
                        </div>

						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Social security number : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.social_security_number}</span></li>
                        </div>

						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Street address : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.street_address}</span></li>
                        </div>


						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Travell next 18 months : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.travell_next_18_months}</span></li>
                        </div>

						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>Uterine malformation : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.uterine_malformation}</span></li>
                        </div>

						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>weight : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.weight}</span></li>
                        </div>
                        

						
						<div style={{backgroundColor:'white',padding:"5px",paddingLeft:"10px"}}>
                        <li className='list-group-item pl-0 pr-0 pt-2 pb-2 pl-3' style={{borderRadius:"8px"}}> <span style={{fontWeight:"700" ,fontSize:"16px"}}>zipcode : </span><span style={{fontWeight:"500"}}>{dataall?.personal_detail?.zipcode}</span></li>
                        </div>
                        


						
                        


						
						
                        

						
                        
						
                        
					</ul>
					{/* <fieldset>
						<legend>Available Options</legend>
						<div class="form-group row">
							<label for="" class="col-sm-12 col-form-label">Size</label>
							<div class="col-sm-12">
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize1" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize1">34</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize2" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize2">36</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize3" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize3">38</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize4" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize4">40</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize5" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize5">42</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize6" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize6">44</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize7" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize7">46</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize8" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize8">48</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize9" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize9">50</label>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<label for="" class="col-sm-12 col-form-label">Color</label>
							<div class="col-sm-12">
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize1" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize1">
										<div class="p-2 mt-1" style={{backgroundColor:"#ffcc00"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize2" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize2">
										<div class="p-2 mt-1" style={{backgroundColor:"#66cccc"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize3" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize3">
										<div class="p-2 mt-1" style={{backgroundColor:"#0fabbc"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize4" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize4">
										<div class="p-2 mt-1" style={{backgroundColor:"#004445"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize5" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize5">
										<div class="p-2 mt-1" style={{backgroundColor:"#eb4d55"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize6" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize6">
										<div class="p-2 mt-1" style={{backgroundColor:"#ff9d76"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize7" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize7">
										<div class="p-2 mt-1" style={{backgroundColor:"#90303d"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize8" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize8">
										<div class="p-2 mt-1" style={{backgroundColor:"#015668"}}></div>
									</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="radioSize9" name="radio_size" class="custom-control-input"/>
									<label class="custom-control-label" for="radioSize9">
										<div class="p-2 mt-1" style={{backgroundColor:"#ffd369"}}></div>
									</label>
								</div>
							</div>
						</div>
					</fieldset> */}
					{/* <div class="input-group input-group-lg mb-4">
						<input type="number" class="form-control text-center" placeholder="Quantity" id="" name="" value="1" min="1" max="5" />
						<div class="input-group-append">
							<span class="input-group-text">Quantity</span>
						</div>
					</div> */}
					{/* <div class="btn-group btn-group-lg" role="group" aria-label="Basic example">
						<button type="button" class="btn btn-secondary">
							<i class="fas fa-cart-plus"></i>
							<span>Add Cart</span>
						</button>
						<button type="button" class="btn btn-secondary"><i class="fas fa-shopping-cart"></i></button>
						<button type="button" class="btn btn-secondary"><i class="fas fa-heart"></i></button>
						<button type="button" class="btn btn-secondary"><i class="fas fa-exchange-alt"></i></button>
					</div> */}
				</div>
			</div>
		</div>
		{/* <div class="col-md-12 mt-4">
			<ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
				<li class="nav-item">
					<Link class="nav-link lead active" role="tab" data-toggle="tab" to="#tabDescription">Description</Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link lead" role="tab" data-toggle="tab" to="#tabSpecification">Specification</Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link lead" role="tab" data-toggle="tab" to="#tabReview">Reviews</Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link lead" role="tab" data-toggle="tab" to="#tabFaq">FAQ's</Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link lead" role="tab" data-toggle="tab" to="#tabVideo">Video</Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link lead" role="tab" data-toggle="tab" to="#tabBlog">Blog</Link>
				</li>
			</ul>
			<div class="tab-content" id="myTabContent">
				<div class="tab-pane pt-4 active" role="tabpanel" id="tabDescription">
					<p>Morbi et feugiat massa, ut pretium felis. Fusce lobortis quam a turpis iaculis accumsan. In ac odio vitae augue vulputate egestas. Nulla iaculis mi in lectus accumsan pellentesque. Integer neque est, imperdiet eget ex at, suscipit euismod eros. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed malesuada elementum accumsan. Suspendisse rhoncus accumsan orci, id blandit ante accumsan viverra. Etiam id tortor in arcu suscipit iaculis. Donec efficitur, leo nec pulvinar pulvinar, nibh ligula feugiat augue, euismod condimentum turpis nulla nec ipsum. Praesent id tristique massa, id rhoncus diam. Sed vel odio porttitor, pretium augue nec, placerat neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac elit sapien. Nullam semper laoreet ligula ut varius. Fusce interdum scelerisque porttitor.</p>
					<p>Nunc mollis velit libero, vel semper lorem bibendum eu. Nam in purus arcu. Quisque et tortor ac nunc ullamcorper interdum vel eget sem. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam ultrices cursus turpis interdum vulputate. Nullam vulputate, nisi ut hendrerit rhoncus, nunc nibh ultrices lorem, at facilisis lorem mauris id ex. Integer nec faucibus odio. Sed nec risus ut nisi tempus viverra. Vivamus hendrerit faucibus lorem. Curabitur vel laoreet est, nec accumsan lacus.</p>
					<p>Maecenas tempus eu ex vitae vulputate. Cras non elit scelerisque, tempus risus eu, porta ipsum. Morbi elementum, lorem a viverra dictum, nibh urna sollicitudin elit, at vestibulum nisi urna at metus. Vivamus id volutpat nibh, finibus vehicula velit. In hac habitasse platea dictumst. Aliquam pharetra lectus massa, et dictum felis molestie et. Nunc sed nibh ac felis consequat consectetur ut sed lorem. Suspendisse tempus tincidunt eros vel pharetra. Mauris pellentesque lacus posuere lacus pellentesque lacinia. Nunc sit amet eros eu ipsum cursus pharetra. Mauris non nunc enim. Vestibulum lectus leo, dictum ut risus sed, semper tristique turpis. Etiam nec libero sed libero tristique condimentum.</p>
					<p>In hac habitasse platea dictumst. Integer ut dignissim diam. Mauris varius eget erat quis sollicitudin. Sed congue nulla lacus. Proin nec hendrerit eros. Proin consequat vehicula quam commodo vestibulum. Etiam nec ligula mattis, laoreet ipsum vel, ultricies nisi. Vestibulum ut dignissim velit. In gravida velit vel vestibulum viverra. Sed convallis at turpis ut pharetra. Nulla eleifend urna arcu, a semper eros ultrices sit amet. Vivamus eget sapien ipsum. Etiam euismod, ante quis pellentesque pellentesque, mauris leo rhoncus dui, ut faucibus felis dui non odio. Etiam eleifend lobortis eros, sed aliquam elit fermentum et. Sed vel lectus accumsan, tempor magna venenatis, euismod orci.</p>
				</div>
				<div class="tab-pane pt-4" role="tabpanel" id="tabSpecification">
					<table class="table table-bordered">
						<thead>
							<tr>
								<td colspan="2"><strong>Memory</strong></td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>test 1</td>
								<td>16GB</td>
							</tr>
						</tbody>
						<thead>
							<tr>
								<td colspan="2"><strong>Processor</strong></td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>No. of Cores</td>
								<td>4</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="tab-pane pt-4" role="tabpanel" id="tabReview">
					<div class="card mb-4">
						<div class="card-header">Write a read</div>
						<div class="card-body">
							<form>
								<div class="form-group row">
									<label for="" class="col-sm-12 col-form-label">Your Name</label>
									<div class="col-sm-12">
										<input type="text" class="form-control" id="" name="" placeholder="Your Name" />
									</div>
								</div>
								<div class="form-group row">
									<label for="" class="col-sm-12 col-form-label">Your Review</label>
									<div class="col-sm-12">
										<textarea class="form-control" id="" name="" placeholder="Your Review" rows="3"></textarea>
										<small id="" class="form-text text-muted">Note: HTML is not translated!</small>
									</div>
								</div>
							</form>
						</div>
						<div class="card-footer">
							<input class="btn btn-primary" type="submit" value="Submit"/>
						</div>
					</div>
					<div class="card">
						<div class="card-header">Write a review</div>
						<div class="card-body">
							<table class="table table-bordered">
								<tbody>
									<tr>
										<td colspan="2">
											<strong>Ilea Heidemann</strong>
										</td>
									</tr>
									<tr>
										<td colspan="2">
											<p>Quaerat et sit sequi unde nostrum, accusantium accusamus ad placeat. Libero sit ut sit ut in consequuntur, sed recusandae esse; qui eum alias fuga ratione ut reiciendis commodi et laboriosam? Earum eveniet et neque est alias commodi voluptatem veniam est. Ad aut sit excepturi unde laudantium voluptatem reiciendis nostrum eos. Molestiae omnis consectetur, culpa in sed aliquam porro quas asperiores. Eum modi, a incidunt dolor ut molestiae aut accusamus aspernatur! Tenetur sed eveniet nesciunt, quam reprehenderit modi repellat quasi voluptatem. Sit quia nulla similique omnis in ipsa quasi ipsam consectetur!</p>
											<p class="m-0">18/03/2013</p>
										</td>
									</tr>
									<tr>
										<td colspan="2">
											<strong>Customer Rating: </strong>
											<span class="fa fa-stack">
												<i class="fas fa-star fa-stack-1x"></i>
												<i class="far fa-star fa-stack-1x"></i>
											</span>
											<span class="fa fa-stack">
												<i class="far fa-star fa-stack-1x"></i>
											</span>
											<span class="fa fa-stack">
												<i class="far fa-star fa-stack-1x"></i>
											</span>
											<span class="fa fa-stack">
												<i class="far fa-star fa-stack-1x"></i>
											</span>
											<span class="fa fa-stack">
												<i class="far fa-star fa-stack-1x"></i>
											</span>
										</td>
									</tr>
								</tbody>
							</table>
							<table class="table table-bordered">
								<tbody>
									<tr>
										<td colspan="2">
											<strong>Jelea lehwe</strong>
										</td>
									</tr>
									<tr>
										<td colspan="2">
											<p>Libero sit ut sit ut in consequuntur, sed recusandae esse; qui eum alias fuga ratione ut reiciendis commodi et laboriosam? Earum eveniet et neque est alias commodi voluptatem veniam est. Ad aut sit excepturi unde laudantium voluptatem reiciendis nostrum eos. Molestiae omnis consectetur, culpa in sed aliquam porro quas asperiores.</p>
											<p class="m-0">18/03/2013</p>
										</td>
									</tr>
									<tr>
										<td colspan="2">
											<strong>Customer Rating: </strong>
											<span class="fa fa-stack">
												<i class="fas fa-star fa-stack-1x"></i>
												<i class="far fa-star fa-stack-1x"></i>
											</span>
											<span class="fa fa-stack">
												<i class="far fa-star fa-stack-1x"></i>
											</span>
											<span class="fa fa-stack">
												<i class="far fa-star fa-stack-1x"></i>
											</span>
											<span class="fa fa-stack">
												<i class="far fa-star fa-stack-1x"></i>
											</span>
											<span class="fa fa-stack">
												<i class="far fa-star fa-stack-1x"></i>
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="tab-pane pt-4" role="tabpanel" id="tabFaq">
					<div class="accordion" id="accordionExample">
						<div class="card">
							<div class="card-header bg-white" id="headingOne" data-toggle="collapse" data-target="#collapseOne">Collapsible Group Item #1</div>
							<div id="collapseOne" class="collapse show" data-parent="#accordionExample">
								<div class="card-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header bg-white" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo">Collapsible Group Item #2</div>
							<div id="collapseTwo" class="collapse" data-parent="#accordionExample">
								<div class="card-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header bg-white" id="headingThree" data-toggle="collapse" data-target="#collapseThree">Collapsible Group Item #3</div>
							<div id="collapseThree" class="collapse" data-parent="#accordionExample">
								<div class="card-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</div>
							</div>
						</div>
					</div>
				</div>
				<div class="tab-pane pt-4" role="tabpanel" id="tabVideo">
					<div class="row">
						<div class="col-md-3">
							<div class="card">
								<img class="card-img-top" src="https://via.placeholder.com/200x200" alt=""/>
								<div class="card-footer">
									<p class="card-text"><Link to="#">Usage Video</Link></p>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="card">
								<img class="card-img-top" src="https://via.placeholder.com/200x200" alt=""/>
								<div class="card-footer">
									<p class="card-text"><Link to="#">Settings Video</Link></p>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="card">
								<img class="card-img-top" src="https://via.placeholder.com/200x200" alt=""/>
								<div class="card-footer">
									<p class="card-text"><Link to="#">Experiences Video</Link></p>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="card">
								<img class="card-img-top" src="https://via.placeholder.com/200x200" alt=""/>
								<div class="card-footer">
									<p class="card-text"><Link to="#">Customer Reviews Video</Link></p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="tab-pane pt-4" role="tabpanel" id="tabBlog">
					<div class="row">
						<div class="col-md-6">
							<div class="card mb-4">
								<div class="row no-gutters">
									<div class="col-md-4">
										<img class="card-img" alt="" src="https://via.placeholder.com/200x230" />
									</div>
									<div class="col-md-8">
										<div class="card-body">
											<h6 class="card-title">Praesent convallis lacus sed est gravida ultricies.</h6>
											<p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
											<p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="card mb-4">
								<div class="row no-gutters">
									<div class="col-md-4">
										<img class="card-img" alt="" src="https://via.placeholder.com/200x230" />
									</div>
									<div class="col-md-8">
										<div class="card-body">
											<h6 class="card-title">Praesent convallis lacus sed est gravida ultricies.</h6>
											<p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
											<p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="card">
								<div class="row no-gutters">
									<div class="col-md-4">
										<img class="card-img" alt="" src="https://via.placeholder.com/200x230" />
									</div>
									<div class="col-md-8">
										<div class="card-body">
											<h6 class="card-title">Praesent convallis lacus sed est gravida ultricies.</h6>
											<p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
											<p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="card">
								<div class="row no-gutters">
									<div class="col-md-4">
										<img class="card-img" alt="" src="https://via.placeholder.com/200x230" />
									</div>
									<div class="col-md-8">
										<div class="card-body">
											<h6 class="card-title">Praesent convallis lacus sed est gravida ultricies.</h6>
											<p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
											<p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> */}
	</div>
	{/* <div class="row"></div>
	<div class="row"></div>
	<div class="row"></div>
	<div class="row"></div>
	<div class="row"></div>
	<div class="row"></div>
	<div class="row"></div>
	<div class="row"></div> */}
	{/* <div class="row mb-5">
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-3">
							<i class="float-left mr-3 fa-3x fas fa-shipping-fast"></i>
							<p class="h5">Free shipping & Return <br/><small class="text-muted">For all orders over $500</small></p>
						</div>
						<div class="col-md-3">
							<i class="float-left mr-3 fa-3x fas fa-thumbs-up"></i>
							<p class="h5">Customer Protection <br/><small class="text-muted">From click to delivery.</small></p>
						</div>
						<div class="col-md-3">
							<i class="float-left mr-3 fa-3x fas fa-umbrella"></i>
							<p class="h5">Safe Payment <br/><small class="text-muted">Use world’s most secure payment methods.</small></p>
						</div>
						<div class="col-md-3">
							<i class="float-left mr-3 fa-3x fas fa-life-ring"></i>
							<p class="h5">Support 24/7 <br/><small class="text-muted">We answer for question all time</small></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> */}
	{/* <div class="row mb-5">
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<p class="lead">We’ll never share your email address with a third-party.</p>
					<div class="input-group input-group-lg">
						<div class="input-group-prepend">
							<span class="input-group-text">Sign Up For Newsletter</span>
						</div>
						<input type="text" id="" name="" class="form-control" placeholder="Your email address" />
						<div class="input-group-append">
							<button class="btn btn-outline-secondary" type="button">Subscribe</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> */}
</div>
{/* <div class="container">
	<div class="row mb-5">
		<div class="col-md-12 mb-5">
			<div class="card">
				<div class="card-header">
					<p class="m-0 lead text-center">follow us on social media</p>
				</div>
				<div class="card-body">
					<ul class="nav justify-content-center">
						<li class="nav-item">
							<Link class="nav-link" to="#"><i class="fa-3x fab fa-facebook-f"></i></Link>
						</li>
						<li class="nav-item">
							<Link class="nav-link" to="#"><i class="fa-3x fab fa-twitter"></i></Link>
						</li>
						<li class="nav-item">
							<Link class="nav-link" to="#"><i class="fa-3x fab fa-instagram"></i></Link>
						</li>
						<li class="nav-item">
							<Link class="nav-link" to="#"><i class="fa-3x fab fa-google-plus-g"></i></Link>
						</li>
						<li class="nav-item">
							<Link class="nav-link" to="#"><i class="fa-3x fab fa-youtube"></i></Link>
						</li>
						<li class="nav-item">
							<Link class="nav-link" to="#"><i class="fa-3x fab fa-pinterest"></i></Link>
						</li>
						<li class="nav-item">
							<Link class="nav-link" to="#"><i class="fa-3x fab fa-linkedin-in"></i></Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-header">Corporate</div>
				<ul class="list-group list-group-flush">
					<li class="list-group-item p-2"><Link to="#" title="">About Us</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Company Profile</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Blog</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Site Maps</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Contact Us</Link></li>
				</ul>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-header">Services</div>
				<ul class="list-group list-group-flush">
					<li class="list-group-item p-2"><Link to="#" title="">Discounts</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Campaigns</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Brands</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Gift Certificates</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Returns</Link></li>
				</ul>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-header">Account</div>
				<ul class="list-group list-group-flush">
					<li class="list-group-item p-2"><Link to="#" title="">Sign In</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Sign up</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">My Account</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Order History</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Wish List</Link></li>
				</ul>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-header">Support</div>
				<ul class="list-group list-group-flush">
					<li class="list-group-item p-2"><Link to="#" title="">FAQ</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Ticket</Link></li>
					<li class="list-group-item p-2"><Link to="#" title="">Feedback</Link></li>
				</ul>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card">
				<div class="card-header">Contact Us</div>
				<div class="card-body">
					<p>We are a team of designers and developers that create high quality Magento, Prestashop, Opencart.</p>
					<p class="m-0"><strong>Address</strong></p>
					<p>4710-4890 Breckinridge St, UK Burlington, VT 05401</p>
					<p class="m-0"><strong>Need Help?</strong></p>
					<p>Call: 1-800-345-6789</p>
				</div>
			</div>
		</div>
		<div class="col-md-12 mt-5 mb-4">
			<ul class="nav justify-content-center">
				<li class="nav-item">
					<Link class="nav-link" to="#"><i class="fa-2x fab fa-cc-diners-club"></i></Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link" to="#"><i class="fa-2x fab fa-cc-amex"></i></Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link" to="#"><i class="fa-2x fab fa-cc-discover"></i></Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link" to="#"><i class="fa-2x fab fa-cc-jcb"></i></Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link" to="#"><i class="fa-2x fab fa-cc-mastercard"></i></Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link" to="#"><i class="fa-2x fab fa-cc-paypal"></i></Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link" to="#"><i class="fa-2x fab fa-cc-visa"></i></Link>
				</li>
			</ul>
		</div>
		<div class="col-md-12">
			<ul class="nav justify-content-center">
				<li class="nav-item">
					<Link class="nav-link" to="#">Privacy Policy</Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link" to="#">Terms & Conditions</Link>
				</li>
				<li class="nav-item">
					<Link class="nav-link" to="#">Site Map</Link>
				</li>
			</ul>
		</div>
		<div class="col-md-12">
			<p class="text-center">Copyright © 2019 Your Store All Right Reserved.</p>
		</div>
	</div>
</div> */}
             
            </div>
           
            
            
 
            
           
          </section>
        </div>
        <Footer />
      
      
      </body>
{/* hhhhh */}


    </>
  )
}

export default SurrogateDetail
